<template>
  <div class="d-flex flex-column flex-grow-1">
    <document-toolbar
      :tab="tab"
      :save="saveDocument"
      :revert="refreshDocument"
      :specialActions="accountContactActions"
      v-on="$listeners"
      :show-delete="userIsMasterAdmin && !cacheDocIsActive"
      :delete-document="deleteDocument"
      :labels.sync="computedLabels"
      :show-labels="!documentIsNew"
    />
    <document-in-tab>
      <cot-form
        ref="form"
        :value="tab.canSave"
      >

        <div class="div-width">
          <v-row dense>
            <v-col
              cols="7"
              class="mb-3"
            >
              <v-card>
                <cot-form
                  :disabled="!canEdit"
                  v-model="contactForm"
                  ref="contactForm"
                  @input="validateMainForm"
                >
                  <v-card-title>
                    {{$t('t.Contact')}}
                    <v-spacer />
                    <div
                      class="d-flex flex-row justify-end"
                      v-if="!documentIsNew"
                    >
                      <v-chip :color="computedCguAcceptedAt ? 'success' : 'error'">
                        <div
                          class="d-flex flex-row"
                          v-if="computedCguAcceptedAt"
                        >
                          <span class="mr-1">{{$t('t.CGUAccepted')}}</span>
                          <span
                            class="mr-1"
                            v-if="cguCalendarLinkText"
                          >{{cguCalendarLinkText}}</span>
                          <calendar-date
                            :date="computedCguAcceptedAt"
                            :lowerCase="true"
                          />
                        </div>
                        <span v-else>
                          {{$t('t.CGUAcceptancePending')}}
                        </span>
                      </v-chip>
                      <v-chip
                        class="ml-2"
                        v-if="computedIsLocked"
                        color="error"
                      >
                        <v-icon class="mr-2">{{$icon('i.Lock')}}</v-icon>
                        <span>{{$t('t.IsLocked')}}</span>
                      </v-chip>
                    </div>
                  </v-card-title>

                  <v-card-text>
                    <v-row
                      dense
                      class="d-flex flox-row align-center"
                    >
                      <v-col cols="5">
                        <div class="d-flex flex-row align-center">
                          <v-checkbox
                            :label="$t('t.Active')"
                            v-model="computedIsActive"
                            class="mr-2"
                          />
                          <v-checkbox
                            :label="$t('t.PortalAccess')"
                            v-model="computedIsAllowPortalAccess"
                            class="mr-2"
                          />
                          <v-checkbox
                            v-if="!computedIsActive"
                            :label="$t('t.IsAnonymous')"
                            v-model="computedIsAnonymous"
                            class="mr-2"
                          />
                        </div>
                      </v-col>
                      <v-spacer />
                    </v-row>
                    <v-row dense>
                      <v-col cols="1">
                        <v-text-field
                          :label="$t('t.Title')"
                          v-model="computedTitle"
                        />
                      </v-col>
                      <v-col>
                        <v-text-field
                          :label="$t('t.Name')"
                          v-model="computedFullname"
                          :rules="required"
                        />
                      </v-col>
                    </v-row>
                    <v-row dense>
                      <v-col>
                        <v-text-field
                          type="email"
                          :prepend-inner-icon="$icon('i.Email')"
                          :label="$t('t.Email')"
                          :rules="[...(computedIsAllowPortalAccess?required:[])]"
                          v-model="computedEmail"
                        />
                      </v-col>
                    </v-row>

                    <v-row dense>
                      <v-col cols="2">
                        <v-select
                          :items="messagingCultures"
                          item-text="name"
                          item-value="code"
                          :label="$t('t.UserCulture')"
                          v-model="computedCulture"
                        />
                      </v-col>
                      <v-col cols="3">
                        <v-text-field
                          :label="$t('t.JobTitle')"
                          v-model="computedJobTitle"
                        />
                      </v-col>
                    </v-row>
                    <v-row dense>

                      <v-col>
                        <v-text-field
                          :label="$t('t.AvailabilityHours')"
                          v-model="computedAvailabilityHours"
                        />
                      </v-col>
                    </v-row>

                    <v-row dense>
                      <v-col>
                        <v-text-field
                          :label="$t('t.Phone')"
                          v-model="computedPhone1"
                        >
                          <template v-slot:prepend-inner>
                            <phone-btn
                              :phone-number="computedPhone1"
                              :show-phone-number="false"
                              normal
                            />
                          </template>
                        </v-text-field>
                      </v-col>
                      <v-col>
                        <v-text-field
                          :label="$t('t.Phone2')"
                          v-model="computedPhone2"
                        >
                          <template v-slot:prepend-inner>
                            <phone-btn
                              :phone-number="computedPhone2"
                              :show-phone-number="false"
                              normal
                            />
                          </template>
                        </v-text-field>
                      </v-col>
                      <v-col>
                        <v-text-field
                          :label="$t('t.Fax')"
                          v-model="computedFax"
                        />
                      </v-col>
                    </v-row>
                    <v-row dense>
                      <v-col cols="12">
                        <v-text-field
                          :label="$t('t.Address1')"
                          v-model="computedAddress1"
                          dense
                        />
                      </v-col>
                      <v-col cols="12">
                        <v-text-field
                          :label="$t('t.Address2')"
                          v-model="computedAddress2"
                          dense
                        />
                      </v-col>
                      <v-col cols="12">
                        <v-text-field
                          :label="$t('t.Address3')"
                          v-model="computedAddress3"
                          dense
                        />
                      </v-col>
                    </v-row>
                    <v-row dense>
                      <v-col>
                        <v-text-field
                          :label="$t('t.City')"
                          v-model="computedCity"
                        />
                      </v-col>
                      <v-col>
                        <v-text-field
                          :label="$t('t.StateProvince')"
                          v-model="computedStateProvince"
                        />
                      </v-col>
                      <v-col>
                        <v-text-field
                          :prepend-icon="$icon('i.MapMarker')"
                          :label="$t('t.Country')"
                          v-model="computedCountry"
                        />
                      </v-col>
                      <v-col>
                        <v-text-field
                          :label="$t('t.PostalCode')"
                          v-model="computedPostalCode"
                        />
                      </v-col>
                    </v-row>
                    <v-row dense>
                      <v-col cols="4">
                        <v-text-field
                          class="importKey"
                          :prepend-inner-icon="$icon('i.ImportKey')"
                          :label="$t('t.ImportKey')"
                          v-model="computedImportKey"
                        />
                      </v-col>
                      <v-col cols="auto">
                        <v-checkbox
                          :label="$t('t.IsImportOverwritable')"
                          v-model="computedIsImportOverwritable"
                        />
                      </v-col>
                      <v-col
                        cols="auto my-auto mx-4"
                        v-if="mustSelectDataSource"
                      >
                        <picker
                          :label="$t('t.ImportDataSources')"
                          :document-types="['import-data-sources']"
                          hide-details
                          :selected-id.sync="computedDataSourceId"
                        />
                      </v-col>
                    </v-row>
                  </v-card-text>
                </cot-form>
              </v-card>
            </v-col>
            <v-col class="mb-3">
              <v-card>
                <v-card-title v-if="!computedIsActive">
                  {{$t('t.InactiveContactWarning')}}
                </v-card-title>
                <v-card-text v-else>
                  <v-expansion-panels
                    hover
                    multiple
                    class="border-radius"
                    v-model="openPanels"
                  >
                    <v-expansion-panel :readonly="!computedRelatedAccounts.length">
                      <v-expansion-panel-header class="text-h6">{{$t('t.RelatedToAccounts')}}</v-expansion-panel-header>
                      <v-expansion-panel-content class="mt-4">
                        <cot-form
                          ref="relatedAccountsForm"
                          v-model="relatedAccountsForm"
                          @input="validateMainForm"
                          :disabled="!canEdit"
                        >
                          <div v-if="computedRelatedAccounts.length">
                            <v-row
                              v-for="a in paginatedRelatedAccounts"
                              :key="a.accountId"
                              class="flex-nowrap"
                            >
                              <v-col class="text-truncate">
                                <computed-document-ref
                                  :id="a.accountId"
                                  document-type="accounts"
                                  :options="{ hideNavigation: false }"
                                />
                              </v-col>
                              <v-col cols="4">
                                <picker
                                  document-types="contact-groups"
                                  :selected-id.sync="a.contactGroupId"
                                  :clearable="false"
                                  :rules="required"
                                  :label="$t('t.ContactGroup')"
                                  hide-message
                                />
                              </v-col>
                              <v-col cols="1">
                                <v-btn
                                  icon
                                  color="red"
                                  @click="unlink(a.accountId)"
                                >
                                  <v-icon>{{$icon('i.Close')}}</v-icon>
                                </v-btn>
                              </v-col>
                            </v-row>
                            <v-expand-transition>
                              <v-pagination
                                v-model="page"
                                :length="computedTotalPage"
                                total-visible=3
                                v-show="computedTotalPage > 1"
                                :disabled="!validateMainForm()"
                              ></v-pagination>
                            </v-expand-transition>
                          </div>
                          <div v-else>
                            <span class="error--text subtitle-1">{{$t('t.ActiveContactMustBeAssignedToAtLeastOneAccount')}}</span>
                          </div>
                        </cot-form>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                    <v-expansion-panel>
                      <!-- class="bordered-item border-radius">-->
                      <v-expansion-panel-header class="text-h6">{{$t('t.AssignOtherAccounts')}}</v-expansion-panel-header>
                      <v-expansion-panel-content
                        class="expansion-panel-content-height"
                        eager
                      >
                        <picker
                          :disabled="!canEdit"
                          document-types="accounts"
                          :label="$t('t.Accounts')"
                          :excluded-items="linkedAccounts"
                          @update:selected-document="setSelectedDocument($event)"
                          reset-on-select
                        />
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </div>
      </cot-form>
    </document-in-tab>
    <v-dialog
      v-model="showPasswordPopUp"
      max-width=290
      @click:outside="showPasswordPopUp = false"
    >
      <v-card class="pa-0 ma-0">
        <v-card-title>
          <v-icon class="mr-2">{{$icon('i.ChangePassword')}}</v-icon>
          <span>{{$t('t.ChangePassword')}}</span>
        </v-card-title>
        <v-card-text>
          <v-form
            ref="changePasswordForm"
            v-model="changePasswordForm"
          >
            <v-text-field
              v-if="isCurrentUser"
              v-model="currentPassword"
              :label="$t('t.CurrentPassword')"
              :type="showCurrentPassword ? 'text' : 'password'"
            >
              <template v-slot:append>
                <v-btn
                  icon
                  @click="showCurrentPassword = !showCurrentPassword"
                >
                  <v-icon>{{showCurrentPassword ? $icon('i.Eye') : $icon('i.EyeOff')}} </v-icon>
                </v-btn>
              </template>
            </v-text-field>
            <v-text-field
              v-model="newPassword"
              :label="$t('t.NewPassword')"
              :type="showNewPassword ? 'text' : 'password'"
              :rules="ruleNew"
              :hint="authenticationRulesDescription"
              persistent-hint
              @input="$refs.changePasswordForm.validate()"
            >
              <template v-slot:append>
                <v-btn
                  icon
                  @click="showNewPassword = !showNewPassword"
                >
                  <v-icon>{{showNewPassword ? $icon('i.Eye') : $icon('i.EyeOff')}} </v-icon>
                </v-btn>
              </template>
            </v-text-field>
            <v-text-field
              v-model="confirmPassword"
              :label="$t('t.ConfirmPassword')"
              :type="showConfirmPassword ? 'text' : 'password'"
              :rules="ruleConfirmation"
            >
              <template v-slot:append>
                <v-btn
                  icon
                  @click="showConfirmPassword = !showConfirmPassword"
                >
                  <v-icon>{{showConfirmPassword ? $icon('i.Eye') : $icon('i.EyeOff')}} </v-icon>
                </v-btn>
              </template>
            </v-text-field>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            @click="changePassword()"
            :disabled="!newPassword.length || !changePasswordForm"
          >{{$t('t.Save')}}</v-btn>
          <v-btn
            color="primary"
            @click="showPasswordPopUp = false"
          >{{$t('t.Cancel')}}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="showConfirmAddRelatedAccounts"
      max-width=500
    >
      <v-card>
        <v-card-title>{{$t('t.EmailAlreadyUsed')}}</v-card-title>
        <v-card-text>
          {{$t('t.ConfirmationMessageForEmailAlreadyUsed')}}
        </v-card-text>
        <v-card-text class="d-flex align-center">
          <div>{{$t('t.InfoSupForEmailAlreadyUsed')}}</div>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn @click="addRelatedAccounts()">
            {{$t('t.Yes')}}
          </v-btn>
          <v-btn
            color="primary"
            @click="showConfirmAddRelatedAccounts = false"
          >{{$t('t.No')}}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

import DocumentEditor from '@/mixins/document-editor'
import Search from '@/pages/search/controllers'
import ItemList from '@/pages/search/controllers/item-list'
import { getDoc } from '@/wasm/pkg'

export default {
  mixins: [DocumentEditor],
  components: {
    CalendarDate: () => import('@/components/calendar-date'),
    ComputedDocumentRef: () => import('@/components/documents-ref/computed-document-ref'),
    CotForm: () => import('@/components/cot-form'),
    DocumentInTab: () => import('@/components/document-in-tab'),
    DocumentToolbar: () => import('@/components/document-toolbar'),
    PhoneBtn: () => import('@/components/phone-btn'),
    Picker: () => import('@/components/picker')
  },
  async created () {
    Object.assign(this.tab, { cancel: this.cancel })
  },
  activated () {
    this.createSearch()
  },
  deactivated () {
    this.search = undefined
  },
  async mounted () {
    this.createSearch()
    this.loadCultures()
  },
  computed: {
    canEdit () {
      return this.$store.getters.currentUserHasPermission('AccountContacts') || this.userIsMasterAdmin
    },
    cacheDocIsActive () {
      return this.cache?.doc?.isActive
    },
    computedRelatedAccounts: {
      get () {
        return this.dataRelatedAccounts
      },
      set (v) {
        this.dataRelatedAccounts = v
        this.dataDocumentEmit()
      }
    },
    computedAddress1: {
      get () {
        return this.dataAddress1
      },
      set (v) {
        this.dataAddress1 = v
        this.dataDocumentEmit()
      }
    },
    computedAddress2: {
      get () {
        return this.dataAddress2
      },
      set (v) {
        this.dataAddress2 = v
        this.dataDocumentEmit()
      }
    },
    computedAddress3: {
      get () {
        return this.dataAddress3
      },
      set (v) {
        this.dataAddress3 = v
        this.dataDocumentEmit()
      }
    },
    computedStateProvince: {
      get () {
        return this.dataStateProvince
      },
      set (v) {
        this.dataStateProvince = v
        this.dataDocumentEmit()
      }
    },
    computedPostalCode: {
      get () {
        return this.dataPostalCode
      },
      set (v) {
        this.dataPostalCode = v
        this.dataDocumentEmit()
      }
    },
    computedCity: {
      get () {
        return this.dataCity
      },
      set (v) {
        this.dataCity = v
        this.dataDocumentEmit()
      }
    },
    computedCountry: {
      get () {
        return this.dataCountry
      },
      set (v) {
        this.dataCountry = v
        this.dataDocumentEmit()
      }
    },
    computedIsAllowPortalAccess: {
      get () {
        return this.dataIsAllowPortalAccess
      },
      set (v) {
        this.dataIsAllowPortalAccess = v
        this.dataDocumentEmit()
      }
    },
    computedAvailabilityHours: {
      get () {
        return this.dataAvailabilityHours
      },
      set (v) {
        this.dataAvailabilityHours = v
        this.dataDocumentEmit()
      }
    },
    computedTitle: {
      get () {
        return this.dataTitle
      },
      set (v) {
        this.dataTitle = v
        this.dataDocumentEmit()
      }
    },
    computedFullname: {
      get () {
        return this.dataFullname
      },
      set (v) {
        this.dataFullname = v
        this.dataDocumentEmit()
      }
    },
    computedEmail: {
      get () {
        return this.dataEmail
      },
      set (v) {
        this.dataEmail = v
        this.dataDocumentEmit()
      }
    },
    computedCulture: {
      get () {
        return this.dataCulture
      },
      set (v) {
        this.dataCulture = v
        this.dataDocumentEmit()
      }
    },
    computedJobTitle: {
      get () {
        return this.dataJobTitle
      },
      set (v) {
        this.dataJobTitle = v
        this.dataDocumentEmit()
      }
    },
    computedPhone1: {
      get () {
        return this.dataPhone1
      },
      set (v) {
        this.dataPhone1 = v
        this.dataDocumentEmit()
      }
    },
    computedPhone2: {
      get () {
        return this.dataPhone2
      },
      set (v) {
        this.dataPhone2 = v
        this.dataDocumentEmit()
      }
    },
    computedFax: {
      get () {
        return this.dataFax
      },
      set (v) {
        this.dataFax = v
        this.dataDocumentEmit()
      }
    },
    computedImportKey: {
      get () {
        return this.dataImportKey
      },
      set (v) {
        this.dataImportKey = v
        this.dataDocumentEmit()
      }
    },
    computedIsImportOverwritable: {
      get () {
        return this.dataIsImportOverwritable
      },
      set (v) {
        this.dataIsImportOverwritable = v
        this.dataDocumentEmit()
      }
    },
    computedSignature: {
      get () {
        return this.dataSignature
      },
      set (v) {
        this.dataSignature = v
        this.dataDocumentEmit()
      }
    },
    computedIsActive: {
      get () {
        return this.dataIsActive
      },
      set (v) {
        this.dataIsActive = v
        this.dataDocumentEmit()
      }
    },
    computedCguAcceptedAt: {
      get () {
        return this.dataCguAcceptedAt
      },
      set (v) {
        this.dataCguAcceptedAt = v
        this.dataDocumentEmit()
      }
    },
    computedIsLocked: {
      get () {
        return this.dataIsLocked
      },
      set (v) {
        this.dataIsLocked = v
        this.dataDocumentEmit()
      }
    },
    computedIsAnonymous: {
      get () {
        return this.dataIsAnonymous
      },
      set (v) {
        this.dataIsAnonymous = v
        this.dataDocumentEmit()
      }
    },
    computedDataSourceId: {
      get () {
        return this.dataDataSourceId
      },
      set (v) {
        this.dataDataSourceId = v
        this.dataDocumentEmit()
      }
    },
    computedLabels: {
      get () {
        return this.dataLabels
      },
      set (v) {
        this.dataLabels = v
        this.dataDocumentEmit()
      }
    },
    actionItem () {
      return {
        id: this.id,
        type: 'account-contacts'
      }
    },
    actions () {
      const items = new ItemList(
        [this.actionItem],
        this.search
      )

      return items.toArray()[0].actions
    },
    userIsMasterAdmin () {
      return this.$store.getters.isMasterAdmin()
    },
    cacheType () {
      return DocumentEditor.CacheType.UserDetail
    },
    id () {
      return this.tab.documentId
    },
    accountContactActions () {
      if (this.documentIsNew) { return undefined }

      const actions = []
      if (this.canEdit) {
        actions.push({
          icon: this.$icon('i.ChangePassword'),
          text: this.$t('t.ChangePassword'),
          callback: this.openChangePasswordPopUp
        })
      }

      if (this.computedIsLocked && this.canEdit) {
        actions.push({
          icon: this.$icon('i.Unlock'),
          text: this.$t('t.Unlock'),
          callback: this.unlock
        })
      }
      return actions.length ? actions : undefined
    },
    cguCalendarLinkText () {
      const dateForDiff = this.moment(this.computedCguAcceptedAt)
      const days = Math.abs(this.moment().startOf('day').diff(dateForDiff.startOf('day'), 'days'))

      return days > 1 ? this.$t('t.At').toLowerCase() : null
    },
    computedTotalPage () {
      return Math.ceil(this.computedRelatedAccounts.length / this.pageSize)
    },
    isCurrentUser () {
      return this.$store.getters.currentUser.id === this.id
    },
    linkedAccounts () {
      return this.computedRelatedAccounts.map(r => ({ id: r.accountId }))
    },
    login () {
      return this.cache.doc.email
    },
    pageSize () {
      return 5
    },
    paginatedRelatedAccounts () {
      return this.computedRelatedAccounts.slice((this.page - 1) * this.pageSize, this.page * this.pageSize)
    },
    ruleConfirmation () {
      return [v => !this.newPassword.length || this.newPassword === v || this.$t('t.ConfirmationPasswordMismatch')]
    },
    ruleNew () {
      const rule = []
      if (this.authenticationRules?.pattern) {
        const regexp = new RegExp(this.authenticationRules.pattern)
        rule.push(v => regexp.test(v) || this.authenticationRulesDescription)
      }
      return rule
    },
    authenticationRulesDescription () {
      if (this.authenticationRules?.pattern) {
        return this.authenticationRules.patternTranslatedValues.find(t => t.culture === this.$store.getters.currentUser.culture).description
      }
      return null
    }
  },
  data () {
    return {
      authenticationRules: null,
      changePasswordForm: null,
      confirmPassword: '',
      contactForm: false,
      currentPassword: '',
      dataAddress1: null,
      dataAddress2: null,
      dataAddress3: null,
      dataAvailabilityHours: null,
      dataCguAcceptedAt: false,
      dataCity: null,
      dataCountry: null,
      dataCulture: null,
      dataDataSourceId: null,
      dataDocumentEmit: this.$nextTickDedup(this.emitDocument),
      dataEmail: null,
      dataFax: null,
      dataFullname: null,
      dataLabels: [],
      dataGroupId: null,
      dataImportKey: null,
      dataIsActive: true,
      dataIsAllowPortalAccess: true,
      dataIsAnonymous: false,
      dataIsImportOverwritable: true,
      dataIsLocked: false,
      dataJobTitle: null,
      dataPhone1: null,
      dataPhone2: null,
      dataPostalCode: null,
      dataRelatedAccounts: [],
      dataSignature: null,
      dataStateProvince: null,
      dataTitle: null,
      defaultCulture: null,
      mainForm: false, // to check
      messagingCultures: [],
      mustSelectDataSource: false,
      newPassword: '',
      noEmptyContactGroups: false,
      openPanels: [0, 1],
      page: 1,
      relatedAccountContactId: null,
      relatedAccountsForm: false,
      required: [v => !!v || this.$t('t.IsRequired')],
      search: null,
      showConfirmAddRelatedAccounts: false,
      showConfirmPassword: false,
      showNewPassword: false,
      showPasswordPopUp: false
    }
  },
  methods: {
    createSearch () {
      this.search = new Search(Search.actions.GRID).setDocumentType('account-contacts')
    },
    validateMainForm () {
      const valide = Boolean(this.contactForm && this.relatedAccountsForm && this.computedRelatedAccounts.length > 0)
      this.$nextTick(() => this.$emit('document-can-save', { tab: this.tab, value: valide }))
      return valide
    },
    emitDocument () {
      const doc = {
        id: this.tab.documentId,
        address1: this.dataAddress1,
        address2: this.dataAddress2,
        address3: this.dataAddress3,
        availabilityHours: this.dataAvailabilityHours,
        cguAcceptedAt: this.dataCguAcceptedAt,
        city: this.dataCity,
        country: this.dataCountry,
        culture: this.dataCulture,
        email: this.dataEmail,
        fax: this.dataFax,
        fullname: this.dataFullname,
        groupId: this.dataGroupId,
        importDataSourceId: this.dataDataSourceId,
        importKey: this.dataImportKey,
        isActive: this.dataIsActive,
        isAllowPortalAccess: this.dataIsAllowPortalAccess,
        isAnonymous: this.dataIsAnonymous,
        isImportOverwritable: this.dataIsImportOverwritable,
        isLocked: this.dataIsLocked,
        jobTitle: this.dataJobTitle,
        labels: this.dataLabels,
        phone1: this.dataPhone1,
        phone2: this.dataPhone2,
        postalCode: this.dataPostalCode,
        relatedAccounts: this.dataRelatedAccounts,
        signature: this.dataSignature,
        stateProvince: this.dataStateProvince,
        title: this.dataTitle
      }

      if (!this.lodash.isEqual(this.document, doc)) {
        this.document = doc
        this.$emit('update:document', this.lodash.cloneDeep(doc))
      }
    },
    async loadCultures () {
      const r = await this.$http().get('/core/v6/settings/cultures/messaging')
      let { cultures, defaultCulture } = r.data

      cultures = cultures.map(c => {
        return {
          name: this.$t(`t.${c}`),
          code: c
        }
      })

      this.messagingCultures = cultures
      this.defaultCulture = defaultCulture
    },
    async deleteDocument () {
      Search.getActions(this.actionItem)
        .get('delete-account-contact')
        .executeQuick(
          this.actionItem,
          () => this.$router.replace('/').catch(e => {
            if (e.name !== 'NavigationDuplicated') {
              throw e
            }
          })
        )
    },
    async authenticateUserWithPassword (password, ignoreComplexity = false) {
      try {
        await this.$http().authenticate(this.login, password, !!window.localStorage.getItem('authentication'))
      } catch (e) {
        if (e.response && e.response.status === 401) {
          this.$store.dispatch('showErrorSnackbar', this.$t('t.InvalidUserNameOrPassword'))
        } else if (ignoreComplexity && e.response && e.response.status === 406) {
          // We reach here because the password complexity rules changed after the last password update and the current password does not respect it
          // But we don't care, because we are changing it
          return true
        } else {
          this.$store.dispatch('showErrorSnackbar', e.response.data?.message || this.$t('t.InternalError'))
        }
        return false
      }
      return true
    },
    async refreshDocumentHook () {
      this.page = 1

      const userNewSettings = await getDoc(DocumentEditor.CacheType.UserNew)
      this.mustSelectDataSource = userNewSettings.mustSelectDataSource

      if (this.documentIsNew) {
        this.setModule()

        this.document = Object.assign({}, this.document, {
          culture: this.defaultCulture,
          isAccountContact: true,
          isActive: true,
          relatedAccounts: []
        })

        if (this.$route.name === 'new-contact-from-account') {
          await this.$waitFor(() => this.$refs?.contactForm && this.$refs?.relatedAccountsForm)
            .then(() => setTimeout(() => {
              if (this.tab.fromAccountId) {
                this.link({ id: this.tab.fromAccountId })
              }

              this.$emit('document-ready', this.tab)
            }, 300))
        }
      } else {
        // Prevent doing anything if the cache isn't loaded
        if (!this.document?.id) { return }

        this.$emit('document-title-change', { tab: this.tab, value: `${this.tab.typeName} ${this.computedFullname}` })
        this.setModule()
      }

      await this.$waitFor(() => this.$refs?.contactForm && this.$refs?.relatedAccountsForm)
        .then(() => setTimeout(() => {
          this.validateMainForm()
          this.$emit('document-ready', this.tab)
        }, 500))
        .catch(e => {
          // Do not throw if the cache is falsy, that mean the user as already close the document
          if (this.cache) {
            throw e
          }
        })
    },
    setModule () {
      let moduleData = {
        name: 'Contact',
        icon: 'i.AccountContacts'
      }

      if (this.document?.id) {
        moduleData = {
          componentName: 'user-header',
          data: this.document
        }
      }

      this.$store.commit('setModule', moduleData)
    },
    openChangePasswordPopUp () {
      this.$http().get(`core/authentication/rules?forUser=${this.id}`).then(r => { this.authenticationRules = r.data.regexPattern })
      this.showPasswordPopUp = true
    },
    openCGU () {
      this.$router.push('/cgu')
    },
    async changePassword () {
      // Validate user's current password and refresh session token
      if (this.isCurrentUser && !(await this.authenticateUserWithPassword(this.currentPassword, true))) {
        return
      }

      // Change user or contact password
      try {
        await this.$http().put('/core/authentication/password/update', { id: this.id, password: this.newPassword })
      } catch (e) {
        if (e.response && e.response.status === 401) {
          this.$store.dispatch('showErrorSnackbar', this.$t('t.InvalidUserNameOrPassword'))
        } else if (e.response && e.response.status === 406 && e.response.data?.message) {
          this.$store.dispatch('showErrorSnackbar', this.$t(e.response.data?.message))
        } else {
          this.$store.dispatch('showErrorSnackbar')
        }
        return
      }

      // Validate user's new password and get a new session token
      if (this.isCurrentUser && !(await this.authenticateUserWithPassword(this.newPassword))) {
        return
      }

      this.showPasswordPopUp = false

      this.$store.dispatch('showSuccessSnackbar', this.$t('t.Snackbar.SaveConfirmation'))
    },
    setSelectedDocument (account) {
      this.link(account)
    },
    link (account) {
      if (account) {
        this.computedRelatedAccounts.push({ accountId: account.id, contactGroupId: account.contactGroupId })
        this.page = Math.floor(Math.max(0, this.computedRelatedAccounts.length - 1) / this.pageSize) + 1
        this.validateMainForm()
      }
    },
    unlink (accountId) {
      const index = this.computedRelatedAccounts.findIndex(i => i.accountId === accountId)
      this.computedRelatedAccounts.splice(index, 1)
      if (!this.paginatedRelatedAccounts.length && this.page > 1) {
        this.page--
      }
    },
    unlock () {
      this.$http().put(`/core/v6/users/${this.id}/unlock`)
    },
    async save () {
      const apiUrl = '/core/v6/account-contacts'

      if (this.documentIsNew) {
        if (this.document.email) {
          const url = '/core/v6/account-contacts/by-email'
          const response = await this.$http().post(url, { email: this.document.email })
          this.relatedAccountContactId = response?.data.id
        }

        if (this.relatedAccountContactId) {
          this.$nextTick(() => { this.showConfirmAddRelatedAccounts = true })
        }

        const response = await this.$http().post(apiUrl, this.document)
        this.$nextTick(() => {
          this.$router.replace('/account-contacts/' + response.data?.id)
        })
        return response
      } else {
        return await this.$http().put(`${apiUrl}/${this.id}`, this.document)
      }
    },
    async addRelatedAccounts () {
      const apiUrl = `/core/v6/account-contacts/${this.relatedAccountContactId}/add-related-accounts`
      const response = await this.$http().put(apiUrl, { relatedAccounts: this.document.relatedAccounts || [] })
      this.$nextTick(() => {
        this.$router.replace('/account-contacts/' + response.data?.id)
      })
    },
    cancel () {
      this.$removeFromKeepAliveCache()
      this.$destroy()
    }
  },
  watch: {
    document: {
      immediate: true,
      handler (v) {
        this.dataAddress1 = v?.address1
        this.dataAddress2 = v?.address2
        this.dataAddress3 = v?.address3
        this.dataAvailabilityHours = v?.availabilityHours
        this.dataCguAcceptedAt = v?.cguAcceptedAt
        this.dataCity = v?.city
        this.dataCountry = v?.country
        this.dataCulture = v?.culture
        this.dataDataSourceId = v?.importDataSourceId
        this.dataEmail = v?.email
        this.dataFax = v?.fax
        this.dataFullname = v?.fullname
        this.dataImportKey = v?.importKey
        this.dataIsActive = v?.isActive
        this.dataIsAllowPortalAccess = v?.isAllowPortalAccess
        this.dataIsAnonymous = v?.isAnonymous
        this.dataIsImportOverwritable = v?.isImportOverwritable
        this.dataIsLocked = v?.isLocked
        this.dataJobTitle = v?.jobTitle
        this.dataPhone1 = v?.phone1
        this.dataPhone2 = v?.phone2
        this.dataPostalCode = v?.postalCode
        this.dataRelatedAccounts = v?.relatedAccounts || []
        this.dataSignature = v?.signature
        this.dataStateProvince = v?.stateProvince
        this.dataTitle = v?.title
        this.dataLabels = v?.labels
      }
    },
    'document.isActive': function (isActive) {
      if (isActive) {
        this.computedIsAnonymous = !isActive
      }
    },
    showPasswordPopUp: function (isShown) {
      if (!isShown) {
        this.currentPassword = ''
        this.newPassword = ''
        this.confirmPassword = ''
        this.showConfirmPassword = false
        this.showCurrentPassword = false
        this.showNewPassword = false
      }
    }
  },
  props: {
    tab: Object
  }
}
</script>

<style lang="stylus" scoped>
.full-height
  height 100%

.account-contact-infos
  display flex
  overflow auto
  flex-grow 1
  flex-wrap wrap
  width 100%

.v-card
  height calc(100% - 4px)

  .v-skeleton-loader
    margin 0.5em

.div-width
  width calc(100% - 12px)
</style>
